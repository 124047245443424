import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CreatePacketModel from "../../components/models/CreatePacketModel";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  clearPacketState,
  deletePacket,
  getPackets,
} from "../../Redux/Actions/packetAction";
import toast from "react-hot-toast";
import useFilter from "../../hooks/useFilter";
import Loader from "../../components/loader/Loader";
import {
  Cancel,
  Check,
  DeleteOutlineOutlined,
  Edit,
  Monitor,
  Visibility,
} from "@mui/icons-material";
import {
  CREATE_PACKET_FAILED,
  CREATE_PACKET_SUCCESS,
  GET_PACKET_SUCCESS,
  PACKET_DELETE_SUCCESS,
  PACKET_UPDATE_SUCCESS,
} from "../../Redux/constant";
import EditPacketModel from "../../components/models/EditPacketModel";
import AssignScreenToPacketModel from "../../components/models/AssignScreenToPacketModel";
function Packet({ user, socketRef }) {
  const dispatch = useDispatch();
  const { _id } = useParams();
  // const id = _id == null ? "Home" : _id;
  const { isRequest, isSuccess, isFailed, type, packets } = useSelector(
    (state) => ({
      isRequest: state.Packet.isRequest,
      isSuccess: state.Packet.isSuccess,
      isFailed: state.Packet.isFailed,
      type: state.Packet.type,
      packets: state.Packet.packets,
    }),
    shallowEqual
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [id, setId] = useState("");
  const [openAssignModel, setOpenAssignModel] = useState(false);
  const [searchText, setSearchText] = useState("");
  const filtered = useFilter(searchText, packets)();
  //   useEffect(()=>{
  //    dispatch(getPackets());
  //   },[])
  useEffect(() => {
    if (isSuccess) {
      switch (type) {
        case CREATE_PACKET_SUCCESS:
          toast.success("Packet added.");
          dispatch(clearPacketState());
          setOpen(false);
          break;
        case CREATE_PACKET_FAILED:
          toast.error("Packet not added.");
          dispatch(clearPacketState());
          setOpen(false);
          break;
        case GET_PACKET_SUCCESS:
          dispatch(clearPacketState());
          break;
        case PACKET_DELETE_SUCCESS:
          toast.success("Deleted successful.");
          dispatch(clearPacketState());
          break;
        case PACKET_UPDATE_SUCCESS:
          toast.success("Updated successful.");
          dispatch(clearPacketState());
          break;
        default:
          console.log("invalid type");
      }
    }
  }, [isSuccess, isFailed, type]);
  const searchHandler = useCallback(
    (e) => {
      setSearchText(e);
    },
    [searchText]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onEdit = (id) => {
    setId(id);
    setEditOpen(true);
  };
  const onAssign = (id) => {
    setId(id);
    setOpenAssignModel(true);
  };
  return (
    <>
      <Layout title={"Packet"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className={"control-area"}>
                <Grid lg={8}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "33px 0",
                      marginRight: "20px !important",
                      borderRadius: "16px",
                    }}
                  >
                    <PrimarySearchBar
                      value={searchText}
                      onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn">
                    <button
                      //   disabled={id !== "Home" ? false : true}
                      onClick={() => setOpen(true)}
                    >
                      <AddIcon /> Add Packet
                    </button>
                  </div>
                  {/* <div className="user-add-btn">
                  <button className='content-folder-btn' 
                   disabled={id == "Home" ? false : true}
                  onClick={() => setIsFolderOpen(true)}
                  >
                    <AddIcon /> Add Folder
                  </button>
                </div> */}
                </Grid>
              </Box>
            </Grid>
          </div>

          <div className="table-user-con">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "16px",
              }}
            >
              <Table className="table-background">
                <TableHead>
                  <TableRow
                    style={{ borderBottom: "2px solid var(--primary-color)" }}
                  >
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">IP</TableCell>
                    <TableCell align="center">Hex Code</TableCell>
                    <TableCell align="center">TCP</TableCell>
                    <TableCell align="center">UDP</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isRequest ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    filtered.length >= 0 &&
                    filtered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <>
                          <TableRow>
                            <TableCell align="center">{++index}</TableCell>
                            <TableCell align="center">
                              {new Date(row.createdAt).toLocaleString()}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.ip}</TableCell>
                            <TableCell align="center">{row.hexcode}</TableCell>
                            <TableCell align="center">
                              {row.tcp ? <Check /> : <Cancel />}
                            </TableCell>
                            <TableCell align="center">
                              {row.udp ? <Check /> : <Cancel />}
                            </TableCell>
                            <TableCell align="center">
                              <Edit
                                //  onClick={()=>submitLogin(row.email,row.password)}
                                onClick={() => onEdit(row._id)}
                                style={{
                                  cursor: "pointer",
                                  margin: "-3px 5px",
                                  color: "var(--primary-color)",
                                }}
                              />
                              <Monitor
                                onClick={() => onAssign(row._id)}
                                style={{
                                  cursor: "pointer",
                                  margin: "-3px 5px",
                                  color: "var(--primary-color)",
                                }}
                              />
                              <DeleteOutlineOutlined
                                fontSize={"medium"}
                                onClick={() => dispatch(deletePacket(row?._id))}
                                //   onClick={() => askingForDelete(row._id)}
                                sx={{
                                  color: "var(--primary-color)",
                                }}
                              ></DeleteOutlineOutlined>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20,50]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </div>
        </div>
        <CreatePacketModel open={open} onClose={() => setOpen(false)} />
        <EditPacketModel
          open={editOpen}
          id={id}
          onClose={() => {
            setId("");
            setEditOpen(false);
          }}
        />
        <AssignScreenToPacketModel
          open={openAssignModel}
          id={id}
          onClose={() => {
            setId("");
            setOpenAssignModel(false);
          }}
        />
      </Layout>
    </>
  );
}

export default Packet;
