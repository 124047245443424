import React, { useState, useEffect } from "react";
import "./date.css";
import { CalendarDays } from "lucide-react";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import TitleIcon from "@mui/icons-material/Title";
import { MenuItem, Popover, Select } from "@mui/material";
import { SketchPicker } from "react-color";
import SearchField from "../../../components/editor/SearchField";
function DateWidget({
  onAddDate,
  _textElement: textProps,
  handleDateFormatChange,
}) {
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [shadowColorAncherEl, setShadowColorAncherEl] = useState(null);
  const [alignAncherEl, setAlignAncherEl] = useState(null);
  const [fontSizeAncherEl, setFontSizeAncherEl] = useState(null);
  const [fontTransparencyAncherEl, setFontTransparencyAncherEl] =
    useState(null);
  const [canvaColor, setCanvaColor] = useState("black");
  const [shadowCanvaColor, setShadowCanvaColor] = useState("black");
  const [length, setLength] = useState({
    x: 10,
    y: 10,
  });
  const colorOpen = Boolean(colorAncherEl);
  const shadowColorOpen = Boolean(shadowColorAncherEl);
  const alignOpen = Boolean(alignAncherEl);
  const fontSizeOpen = Boolean(fontSizeAncherEl);
  const fontTransparencyOpen = Boolean(fontTransparencyAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const shadowColorId = shadowColorOpen ? "simple-popover" : undefined;
  const alignId = alignOpen ? "simple-popover" : undefined;
  const fontSizeId = fontSizeOpen ? "simple-popover" : undefined;
  const fontTransparencyId = fontTransparencyOpen
    ? "simple-popover"
    : undefined;

  useEffect(() => {
    if (textProps) {
      setLength({
        x: textProps?.textBoxProps?.shadow?.offsetX,
        y: textProps?.textBoxProps?.shadow?.offsetY,
      });
    }
  }, [textProps]);
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleShadowColorClick = (event) => {
    setShadowColorAncherEl(event.currentTarget);
  };
  const handleShadowColorClose = () => {
    setShadowColorAncherEl(null);
  };
  const handleAlignClick = (event) => {
    setAlignAncherEl(event.currentTarget);
  };
  const handleAlignClose = () => {
    setAlignAncherEl(null);
  };
  const handleFontSizeClick = (event) => {
    setFontSizeAncherEl(event.currentTarget);
  };
  const handleFontSizeClose = () => {
    setFontSizeAncherEl(null);
  };
  const handleFontTransparencyClick = (event) => {
    setFontTransparencyAncherEl(event.currentTarget);
  };
  const handleFontTransparencyClose = () => {
    setFontTransparencyAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextFontColor(color.rgb, (properties) =>
      textProps.onSetAllProperties(properties)
    );
  };
  const onChangeShadowColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextBoxShadowColor(color.rgb, (properties) =>
      textProps.onSetAllProperties(properties)
    );
  };
  const onChangeOffsetValues = (e) => {
    setLength((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const _onAddDate = () => {
    const currentDate = new Date().toLocaleDateString('en-GB', { 
      day: "2-digit", 
      month: "2-digit", 
      year: "numeric"
  })
    onAddDate(currentDate);
  };
  const _onChangeTextShadowState = (e) => {
    if (e.target.checked) {
      textProps.onChangeDateShadowState(true, (properties) =>
        textProps.onSetAllProperties(properties)
      );
    } else {
      textProps.onChangeDateShadowState(false, (properties) =>
        textProps.onSetAllProperties(properties)
      );
    }
  };
  return (
    <>
      <div className="date-container">
      {/* <SearchField/> */}
        <button
          style={{
            height: "40px",
            width: "100%",
            background:'var(--primary-color)',
            color:'var(--primary-forecolor)',
            border: "1px solid gray",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={_onAddDate}
        >
          {/* <CalendarDays />  */}
          Add Date
        </button>
        <div className="tool--wrapper">
          <p>Date Format:</p>
          <div className="text--tool">
          <select
            onChange={(e) => handleDateFormatChange(e.target.value)}
            style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
          >
            <option value='{"day": "2-digit", "month": "2-digit", "year": "numeric"}>1'>
              07/11/2024
            </option>
            <option value='{"day": "2-digit", "month": "short","year": "2-digit"}>2'>
              07 Nov, 24
            </option>
            <option value='{"day":"2-digit","month": "long","year": "numeric"}>3'>
              07 November, 2024
            </option>
            <option value='{"day": "2-digit", "month": "2-digit", "year": "numeric"}>4'>
              07-11-2024
            </option>
          </select>
          
          </div>
        </div>
{
  textProps?.textBoxProps?.isSelected && <>
        <div className="tool--wrapper">
          <p>Text Color:</p>
          <div className="text--tool">
            <button className="buttons" onClick={handleColorClick}>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  border: "1px solid gray",
                  borderRadius: "50%",
                  background: `${textProps?.textBoxProps?.fill}`,
                }}
              ></div>
              <span>{textProps?.textBoxProps?.fill}</span>
            </button>
            <Popover
              id={colorId}
              open={colorOpen}
              anchorEl={colorAncherEl}
              onClose={handleColorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <SketchPicker
                color={canvaColor}
                onChangeComplete={onChangeColorComplete}
              />
            </Popover>
          </div>
        </div>

        <div className="tool--wrapper">
          <p>Font Size:</p>
          <div className="text--tool">
            <input
              type={"range"}
              value={textProps?.textBoxProps?.fontSize}
              min={0}
              onChange={(e) =>
                textProps.onChangeTextFontSize(e.target.value, (properties) =>
                  textProps.onSetAllProperties(properties)
                )
              }
            />
          </div>
        </div>
  </>
}

<div className="tool--wrapper">
          <div className='text--tool' style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>
          <p>offsetX:{textProps?.textBoxProps?.offsetX?.toFixed(2)}</p>
          <p>offsetY:{textProps?.textBoxProps?.offsetY?.toFixed(2)}</p>
          </div>
        </div>

        <div className="tool--wrapper">
          <div className='text--tool' style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>
          <p>Width:{textProps?.textBoxProps?.width?.toFixed(2)}</p>
          <p>Height:{textProps?.textBoxProps?.height?.toFixed(2)}</p>
          </div>
        </div>

        {/* <div className="tool--wrapper">
          <p>Shadow:</p>{" "}
          <div className="text--tool">
            <input
              type={"checkbox"}
              style={{ height: "20px", width: "20px" }}
              checked={textProps.textBoxProps.isShadow}
              onChange={_onChangeTextShadowState}
            />
          </div>
        </div> */}

        {textProps.textBoxProps.isShadow && (
          <>
            <div className="tool--wrapper">
              <p>Shadow:</p>
              <div className="text--tool">
                <input
                  type={"range"}
                  value={textProps?.textBoxProps?.shadow?.size}
                  min={0}
                  onChange={(e) =>
                    textProps.onChangeTextBoxShadowBlur(
                      e.target.value,
                      (properties) => textProps.onSetAllProperties(properties)
                    )
                  }
                />
              </div>
            </div>

            <div className="tool--wrapper">
              <p>shadow Color:</p>
              <div className="text--tool">
                <button
                  className="buttons"
                  // className="text-tool-btn-top"
                  onClick={handleShadowColorClick}
                >
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      background: `${textProps?.textBoxProps?.shadow?.color}`,
                    }}
                  ></div>
                  <span>{textProps?.textBoxProps?.shadow?.color}</span>
                  {/* <TextFormatIcon
                            style={{
                              color: `${textProps?.textBoxProps?.shadow?.color}`,
                            }}
                            fontSize="large"
                          /> */}
                </button>
                <Popover
                  id={shadowColorId}
                  open={shadowColorOpen}
                  anchorEl={shadowColorAncherEl}
                  onClose={handleShadowColorClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <SketchPicker
                    color={shadowCanvaColor}
                    onChangeComplete={onChangeShadowColorComplete}
                  />
                </Popover>
              </div>
            </div>

            <div className="tool--wrapper">
              <p>offsetX:</p>
              <div className="text--tool">
                <input
                  type={"number"}
                  value={length.x}
                  name={"x"}
                  onChange={(e) => {
                    onChangeOffsetValues(e);
                    textProps.onGetOffsetVal(
                      { x: e.target.value, y: length.y },
                      (properties) => textProps.onSetAllProperties(properties)
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="tool--wrapper">
              <p>offsetY:</p>
              <div className="text--tool">
                <input
                  type={"number"}
                  value={length.y}
                  name={"y"}
                  onChange={(e) => {
                    onChangeOffsetValues(e);
                    textProps.onGetOffsetVal(
                      { y: e.target.value, x: length.x },
                      (properties) => textProps.onSetAllProperties(properties)
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DateWidget;
