import {
  GET_CONTENT_SUCCESS, DELETE_CONTENT_REQUEST_SUCCESS
  , CHECK_ALL_CONTENT, CHECK_ONE_CONTENT,
  DELETE_ALL_CONTENT, UNCHECK_ONE_CONTENT,
  UNCHECK_ALL_CONTENT, ADD_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST, GET_CONTENT_REQUEST,
  ADD_CONTENT,
  GET_CONTENT_FAILED,
  CONTENT_FLAG_OFF,
  SEARCHING,
  SEARCHING_END,
  CONTENT_SCHEDULE_SUCCESS,
  CONTENT_SCHEDULE_DELETE,
  CLIENT_CONTENT_SUCCESS,
  CONTENT_UPDATED,
  GET_FOLDER_CONTENT_SUCCESS
} from "../constant";

const initial = {
  isContentLoading: false,
  isDeleting: false,
  isDeleted: false,
  isContentUpdated:false,
  isContentAdding: false,
  isContentAdded: false,
  isContentScheduled: false,
  isContentScheduledDeleted: false,
  content: [],
  _content:[],
  clientContent:[]
};
export const ContentReducer = (state = initial, action) => {
  switch (action.type) {
    case CONTENT_FLAG_OFF:
      return {
        ...state,
        isContentAdded: false,
        isContentScheduled: false,
        isContentScheduledDeleted: false,
        isDeleted: false,
        isContentUpdated:false
      }
    case ADD_CONTENT_REQUEST:
      return {
        ...state,
        isContentAdding: true,
      };
    case ADD_CONTENT:
      return {
        ...state,
        isContentAdding: false,
        isContentAdded: true,
        content: [...state.content, { ...action.payload, checked: false }],
        _content: [...state._content, { ...action.payload, checked: false }]
      };
    case GET_CONTENT_REQUEST:
      return {
        ...state,
        isContentLoading: true,
      };
    case GET_CONTENT_SUCCESS:
      let contentArr = [];
      let payload =action.payload ?? [];
      if(payload=='' || payload.length==0){
        payload=[];
      }else{
        action.payload.forEach((c) => contentArr.push({ ...c, checked: false }));
      }
      return {
        ...state,
        isContentLoading: false,
        content: contentArr,
      };
      case GET_FOLDER_CONTENT_SUCCESS:
        let _contentArr = [];
        action?.payload?.forEach((c) => _contentArr.push({ ...c, checked: false }));
        return {
          ...state,
          isContentLoading: false,
          _content: _contentArr,
        };
    case GET_CONTENT_FAILED:
      return {
        ...state,
        isContentLoading: false
      }
    case DELETE_CONTENT_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        content: state.content.filter((con) => con.id !== action.payload),
        _content: state._content.filter((con) => con.id !== action.payload),
      };
    case CHECK_ALL_CONTENT:
      let checkAllContentArr = [];
      state.content.forEach((c) => checkAllContentArr.push({ ...c, checked: true }));
      return {
        ...state,
        content: checkAllContentArr,
      };
    case UNCHECK_ALL_CONTENT:
      let unCheckAllContentArr = [];
      state.content.forEach((c) => unCheckAllContentArr.push({ ...c, checked: false }));
      return {
        ...state,
        content: unCheckAllContentArr,
      };
    case CHECK_ONE_CONTENT:
      const checkOneContentIndex = state.content.findIndex((c) => c.id === action.payload);
      state.content[checkOneContentIndex] = {
        ...state.content[checkOneContentIndex],
        checked: true,
      };
      return {
        ...state,
        content: state.content,
      };
    case UNCHECK_ONE_CONTENT:
      const unCheckOneContentIndex = state.content.findIndex((c) => c.id === action.payload);
      state.content[unCheckOneContentIndex] = {
        ...state.content[unCheckOneContentIndex],
        checked: false,
      };
      return {
        ...state,
        content: state.content,
      };
    case DELETE_ALL_CONTENT:
      let deleteContentArr = [];
      action.payload.forEach((c) => deleteContentArr.push({ ...c, checked: false }));
      return {
        ...state,
        content: deleteContentArr
      };
    case CONTENT_SCHEDULE_SUCCESS:
      state.content[state.content.findIndex(c => c.id === action.payload._id)] = { ...action.payload };
      return {
        ...state,
        isContentScheduled: true,
        content: state.content
      }
    case CONTENT_SCHEDULE_DELETE:
      state.content[state.content.findIndex(c => c.id === action.payload._id)] = { ...action.payload };
      return {
        ...state,
        isContentScheduledDeleted: true,
        content: state.content
      }
    case CLIENT_CONTENT_SUCCESS:
      return {
        ...state,
        clientContent: action.payload
      }
    case CONTENT_UPDATED:
      console.log(action.payload);
      const __findIndexForContentUpdate = state._content.findIndex(c => c.id === action.payload.id);
      const ___findIndexForContentUpdate = state.content.findIndex(c => c.id === action.payload.id);
      state._content[__findIndexForContentUpdate] = { ...action.payload };
      state.content[___findIndexForContentUpdate] = { ...action.payload };
      return {
        ...state,
        _content: state._content,
        content:state.content,
        isContentUpdated:true
      }
    default:
      return state;
  }
};
