import React from "react";
import "../Video/video.css";
// import videoPlayerThumbnail from "../../video-player-thumbnail.png";
import { useSelector } from "react-redux";
import playlist_icon from '../../../Assets/images/playlist-video.png';
function PlaylistCanva({  onLoadPlaylistFromDb }) {
  const { playlist } = useSelector((state) => state.Playlist);
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    // dir.click();
  };
  return (
    <div className="video-component-container">
      {/* <input
        id={"file-get"}
        type={"file"}
        onChange={(e) => onGetVideo(e.target.files[0])}
        hidden
      />
      <button
        style={{
          height: "30px",
          width: "150px",
          border: "1px solid gray",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
        onClick={openDirectory}
      >
        Upload Video
      </button> */}
      <div className="usplash-container">
        {playlist.map((con, index) => {
          
            return (
              <div
                className="upslash-divs"
                onClick={() => onLoadPlaylistFromDb(con?.content,con?.name)}
              >
                <img src={playlist_icon} />
                <span>{con?.name?.substring(0, 20)}</span>
              </div>
            );
          
        })}
      
      </div>
    </div>
  );
}

export default PlaylistCanva;
