import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyContent, emptyContent } from "../../Redux/Actions/formAction";
import {
  addContentInPlaylist,
  addOverlayInPlaylist,
} from "../../Redux/Actions/playlistAction";
import styles from "./tab.module.css";
import Image from "./tabviews/images/Image";
import TemplateTab from "./tabviews/template/TemplateTab";
const tabs = {
  image: Image,
  template: TemplateTab,
};
function OverlayTab(props) {
  const dispatch = useDispatch();
  const { contentForm, isContentAvailable } = useSelector(
    (state) => state.Form
  );
  const { content } = useSelector((state) => state.Content);
  const [type, setType] = useState("image");
  const [selected, setSelected] = useState({});
  const Component = tabs[type];
  useEffect(() => {
    isContentAvailable && dispatch(emptyContent());
  }, []);
  useEffect(() => {
    console.log('con->',content);
    // socketRef.current.emit('leaving--connection')
    dispatch(copyContent(content));
  }, []);
  // const emitOfAddContent=(mac)=>{
  //     const id=setTimeout(() => {
  //         props.socketRef.current?.emit('content_added',{detail:{mac:mac}})
  //         clearTimeout(id);
  //     }, 2000);
  //   }
  const submitContent = () => {
    dispatch(addOverlayInPlaylist({ id: props.pId, value: [{...selected,subtype:'overlay'}] }));
    //    console.log(props.screenMac)
    //    if(props.screenMac!=="" || props.screenMac!==undefined){
    //    emitOfAddContent(props.screenMac);
    //    }
    onClose();
  };
  const onClose = () => {
    setSelected({});
    props.close(false);
  };
  //   console.log('selected:->',selected);
  return (
    <>
      <div className={`${styles.tabContainer}`}>
        <div className={`${styles.tabHeader}`}>
          <div className={`${styles.tabs}`}>
            <button
              className={`${styles.tabBtn} ${
                type === "image" ? styles.tabActive : ""
              }`}
              onClick={() => setType("image")}
            >
              Image & Template
            </button>
            {/* <button
              className={`${styles.tabBtn} ${
                type === "template" ? styles.tabActive : ""
              }`}
              onClick={() => setType("template")}
            >
              Templates
            </button> */}
          </div>
        </div>
        <div className={`${styles.tabBody}`}>
          <div className={`${styles.innerBody}`}>
            <Component content={content} getSelected={(e) => setSelected(e)} pId={props.pId} />
          </div>
        </div>
        <div className={`${styles.tabFooter}`}>
          <button className="btn-cancel" onClick={onClose}>
            close
          </button>
          <button className="btn-save" onClick={submitContent}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default OverlayTab;
