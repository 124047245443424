import React from "react";
import "../Text/text.css";
function ToolPlaylistOp({ playlist }) {
  const onChange = (e) => {
    const { value } = e.target;
    let obj = {};
    if (value == "fitXY") {
      obj.fitXY = true;
      obj.none = false;
    }
    if (value == "none") {
      obj.fitXY = false;
      obj.none = true;
    }
    playlist.onTypeChange(obj, (properties) => {
      playlist.onSetAllProperties(properties);
    });
  };
  return (
    <>
      <div className={"text-container"}>
        <p>{playlist.playlistProps.type == "playlist" && "Playlist Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-video" && "Video Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-image" && "Image Scale"}</p>
        <div className="tool-wrapper">
        <p style={{textTransform:'capitalize'}}>{playlist.playlistProps?.name?.substr(0,20)}</p>
        {/* <p>{playlist.playlistProps.type == "playlist" && <>{playlist.playlistProps?.name?.substr(0,20)}</>}</p>
        <p>{playlist.playlistProps.type == "nor-video" && "Video Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-image" && "Image Scale"}</p> */}
        </div>
        <div className={"tool--wrapper"}>
          <p>Fit XY</p>
          <div className={"text--tool"}>
            <span>Yes</span>{" "}
            <input
              type="radio"
              value={"fitXY"}
              checked={playlist?.playlistProps?.fitXY ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
            <span>No</span>{" "}
            <input
              type="radio"
              value={"none"}
              checked={playlist?.playlistProps?.none ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className='text--tool' style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>
          <p>offsetX:{playlist?.playlistProps?.offsetX?.toFixed(2)}</p>
          <p>offsetY:{playlist?.playlistProps?.offsetY?.toFixed(2)}</p>
          </div>
        </div>

        <div className="tool--wrapper">
          <div className='text--tool' style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>
          <p>width:{playlist?.playlistProps?.width?.toFixed(2)}</p>
          <p>height:{playlist?.playlistProps?.height?.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ToolPlaylistOp;
