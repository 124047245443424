import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./screen.css";
import { DesktopMacOutlined } from "@mui/icons-material";
import {
  deleteScreen,
  addScreen,
  getScreens,
  screenFlagOff,
  updateScreen,
  moveScreenToFolder,
  getScreensOnFolder,
  licensUpdate,
  licenseReUpdate,
} from "../../Redux/Actions/screenAction";
import { useNavigate, useParams } from "react-router-dom";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getContent } from "../../Redux/Actions/contentAction";
import toast from "react-hot-toast";
import FolderWrapper from "../../components/folder/FolderWrapper";
import FolderComponent from "../../components/folder/FolderComponent";
import FolderModel from "../../components/models/FolderModel";
import MoveFolder from "../../components/models/MoveFolder";
import {
  _GetFoldersByUser,
  GetFolders,
} from "../../Redux/Actions/folderAction";
import useFilter from "../../hooks/useFilter";
import ScreenTable from "./components/ScreenTable";
import AlertModel from "../../components/models/AlertModel";

function Screen({ socketRef, user }) {
  const { _id } = useParams();
  const id = _id == null ? "Home" : _id;
  console.log("id=>", id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    // screens,
    isScreenAdded,
    isAdded,
    isScreenDeleted,
    mac,
    screenLoading,
    isScreenUpdated,
    isScreenMoving,
  } = useSelector((state) => state.Screen);
  const { _folders, folders, screens, packets } = useSelector(
    (state) => ({
      folders: state.Folder.folders.filter(
        (f) => f.module == "screen" && f.parent == id
      ),
      _folders: state.Folder.folders.filter((f) => f.module == "screen"),
      screens: state.Screen.screens,
      packets: state.Packet.packets,
    }),
    shallowEqual
  );
  const [openModel, setOpenModel] = useState(false);
  const [nMac, setNMac] = useState("");
  const [screenId, setScreenId] = useState({ id: "", mac: "" });
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [orientation, setOrietation] = useState("");
  const [license, setLicense] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const macRef = useRef();
  const [textVal, setTextVal] = useState("");
  const [packetTextSearch, setPacketTextSearch] = useState("");
  const [filteredScreen, setFilteredScreen] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [syncList, setSyncList] = useState([]);
  const [openCreatefolder, setOpenCreateFolder] = useState(false);
  const [isOpenFolderModel, setIsOpenFolderModel] = useState(false);
  const [_screenId, setScreenIds] = useState("");
  const [_folderId, setFolderIds] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [openDeleteFolder, setOpenDeleteFolder] = useState(false);
  const [countScreen, setCountScreen] = useState(0);
  const [packet, setPacket] = useState([]);
  const [selected, setSelected] = useState([]);
  const [path, setPath] = useState([
    { id: "home", name: "home", parent: null },
  ]);
  const filtered = useFilter(textVal, screens)();
  const packetFiltered = useFilter(packetTextSearch, packets)();
  // console.log(filtered);

  useEffect(() => {
    // dispatch(getScreens(user._id));
    socketRef.current.emit("leaving--connection");
    if (user?.role == "user-admin") {
      dispatch(getPlaylist(user?.parentId?._id));
      dispatch(GetFolders(user?.parentId?._id));
    } else {
      dispatch(getPlaylist(user?._id));
      if (user?.role == "user") {
        dispatch(_GetFoldersByUser(user?._id));
      } else {
        dispatch(GetFolders(user?._id));
      }
    }
    // dispatch(getScreens(user?._id));
    // dispatch(getContent(user?._id));
  }, []);
  useEffect(() => {
    if (id !== "Home") {
      dispatch(getScreensOnFolder(id));
      // api.get(`/screen/GetScreensOnFolder/${id}`).then(res=>{
      //   console.log(res)
      // })
    }
  }, [id]);
  useEffect(() => {
    if (isAdded) {
      toast.success("Screen Added");
      setFilteredScreen([...screens]);
      dispatch(screenFlagOff());
      setAlertMsg(true);
    }
    if (isScreenDeleted) {
      socketRef.current?.emit("event:delete", { detail: { mac: mac } });
      dispatch(screenFlagOff());
      toast.success("Screen updated.");
      setFilteredScreen([...screens]);
      dispatch(screenFlagOff());
    }
    if (isScreenUpdated) {
      socketRef.current?.emit("event:delegate", { detail: { mac: mac } });
      dispatch(screenFlagOff());
      toast.success("Screen updated.");
    }
  }, [isAdded, isScreenDeleted, isScreenUpdated, mac]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emitOnDeleteScreen = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("event:delegate", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const deleteScreenHandler = (id, mac) => {
    setScreenId({ id: id, mac: mac });
    setOpenDeleteDialog(true);
  };
  const editScreenHandler = (id, mac) => {
    const _selectedScreen = screens.find((screen) => screen._id == id);
    setName(_selectedScreen?.name);
    setCode(_selectedScreen?.code);
    setOrietation(_selectedScreen?.orientation);
    setScreenId({ id: id, mac: mac });
    setOpenEditDialog(true);
  };
  const confirmDeleteScreen = () => {
    dispatch(deleteScreen(screenId.id));
    emitOnDeleteScreen(screenId.mac);
    setOpenDeleteDialog(false);
  };
  const emitOnAddedScreen = () => {
    const id = setTimeout(() => {
      socketRef.current?.emit("event:delegate", {
        detail: { mac: macRef.current.value, code },
      });
      clearTimeout(id);
    }, 2000);
  };
  const submit = () => {
    if (user?.role == "user-admin") {
      dispatch(
        addScreen(
          {
            name,
            orientation,
            license,
            user: user?.parentId?._id,
            folderId: _id,
          },
          code
        )
      );
    } else {
      dispatch(
        addScreen(
          {
            name,
            orientation,
            license,
            user: user?._id,
            folderId: _id,
          },
          code
        )
      );
    }
    setOpenModel(false);
    emitOnAddedScreen();
    // toast.success("Screen Added.");
    setCode("");
    setName("");
    // setOrientation('');
    setLicense("");
  };

  const onUpdate = () => {
    dispatch(
      updateScreen(
        {
          name,
          orientation,
          packets: selected,
        },
        screenId.id
      )
    );
    setOpenEditDialog(false);
    emitOnAddedScreen();
    // toast.success("Screen Added.");
    setCode("");
    setName("");
    setOrietation("");
  };

  const searchHandler = useCallback(
    (e) => {
      setTextVal(e);
    },
    [textVal]
  );
  const searchPacketHandler = useCallback(
    (e) => {
      setPacketTextSearch(e.target.value);
    },
    [packetTextSearch]
  );
  const onGetSyncList = (e) => {
    const { value, checked } = e.target;
    checked
      ? setSyncList((prev) => [...prev, value])
      : setSyncList(syncList.filter((_sync) => _sync !== value));
  };
  const onSyncScreen = () => {
    if (syncList.length > 0) {
      console.log(syncList);
      syncList.forEach(async (_sync, index) => {
        await socketRef.current.emit(
          "screen:sync",
          { detail: { mac: _sync } },
          function (done, _mac) {
            // if (done) {
            setSyncList((prevSyncList) =>
              prevSyncList.filter((mac) => mac !== _sync)
            );
            //}
          }
        );
      });
    }
  };

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "mon";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "sec";
  }

  const onEditFolder = (id) => {
    setFolderIds(id);
    setIsEditMode(true);
    setOpenCreateFolder(true);
  };
  const onLicenseActive = (id, mac) => {
    dispatch(licensUpdate(id));
  };
  const onSelectLicense = (id, _editMode) => {
    setScreenIds(id);
    setIsEditMode(_editMode);
    setOpenEditDialog(true);
  };
  const onUpdateLicense = () => {
    dispatch(licenseReUpdate(_screenId, license));
    setOpenEditDialog(false);
    setIsEditMode(false);
    setScreenIds("");
    emitOnAddedScreen();
  };

  const onGetScreenAssign = (e) => {
    const { value, checked, name } = e.target;
    // console.log(e)
    if (checked) {
      setPacket((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: true, disable: false } : s
        ),
      ]);

      setSelected((prev) => [...prev, value]);
    } else {
      setPacket((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: false, disable: false } : s
        ),
      ]);

      setSelected((prev) => prev.filter((f) => f != value));
    }

    // }
  };
  const onDeleteFolder = (id) => {
    const _countScreen = screens.filter((s) => s.folderId._id == id).length;
    if (_countScreen == 0) {
      setFolderIds(id);
      setCountScreen(0);
      setOpenDeleteFolder(true);
    } else {
      setFolderIds(id);
      setCountScreen(_countScreen);
      setOpenDeleteFolder(true);
    }
    // alert(`${_countScreen} screens in side your folder do you want to delete it? Screen also will remove.`)
  };
  return (
    <>
      <Layout title={"Screen"} user={user}>
        <input ref={macRef} hidden value={mac} />
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className="control-area">
                <Grid
                  item
                  lg={8.5}
                  sx={{
                    background: "white",
                    padding: "33px 0",
                    borderRadius: "16px",
                    mr: 2,
                  }}
                >
                  <PrimarySearchBar value={textVal} onChange={searchHandler} />
                </Grid>
                <Grid item lg={3.5}>
                  <div className="button-controlls flex flex-column">
                    <button
                      className="add-screen-btn"
                      onClick={() => setOpenModel(true)}
                      style={{
                        background:
                         user.role=="user" ? "var(--primary-color)" : id == "Home" ? "gray" : "var(--primary-color)",
                      }}
                      disabled={user.role=="user" ? false : id !== "Home" ? false : true}
                    >
                      +Add Screen
                    </button>
                    <div className="lower-btn-group flex">
                      <button
                        className="btn-1 main-color"
                        onClick={onSyncScreen}
                      >
                        Sync Screens {syncList.length !== 0 && syncList.length}
                      </button>
                      {user.role != "user" && (
                        <button
                          className="btn-2 main-color"
                          onClick={() => setOpenCreateFolder(true)}
                          style={{
                            background: id == "Home" ? "transparent" : "gray",
                          }}
                          disabled={id == "Home" ? false : true}
                        >
                          Create Folder
                        </button>
                      )}
                      {/* {
                         id==undefined || id=="Home" ? 
                      <button
                        className="btn-2 main-color"
                        onClick={() => setOpenCreateFolder(true)}
                      >
                        Create Folder
                      </button> : <></>
                      } */}
                    </div>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>
          {user.role != "superadmin" && (
            <FolderWrapper>
              {folders?.map((_folder, index) => (
                <FolderComponent
                  key={index}
                  index={index}
                  _folder={_folder}
                  _module={"screen"}
                  onEdit={onEditFolder}
                  onDeleteFolder={onDeleteFolder}
                />
              ))}
            </FolderWrapper>
          )}

          {user.role == "superadmin" ? (
            <ScreenTable
              screenLoading={screenLoading}
              filtered={filtered}
              editScreenHandler={editScreenHandler}
              deleteScreenHandler={deleteScreenHandler}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              onGetSyncList={onGetSyncList}
              role={user.role}
              onLicenseActivate={onLicenseActive}
              onSelectLicense={onSelectLicense}
            />
          ) : id == "Home" ? (
            <></>
          ) : (
            <ScreenTable
              screenLoading={screenLoading}
              filtered={filtered}
              editScreenHandler={editScreenHandler}
              deleteScreenHandler={deleteScreenHandler}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              onGetSyncList={onGetSyncList}
              role={user.role}
              onLicenseActivate={onLicenseActive}
              onSelectLicense={onSelectLicense}
            />
          )}
        </div>
      </Layout>

      <FolderModel
        open={openCreatefolder}
        module={"screen"}
        onClose={() => {
          setOpenCreateFolder(false);
          setIsEditMode(false);
        }}
        parent={id == "" ? "home" : id}
        userId={user?.role == "user-admin" ? user?.parentId?._id : user?._id}
        isEditMode={isEditMode}
        folderId={_folderId}
      />
      <MoveFolder
        isOpen={isOpenFolderModel}
        onClose={() => setIsOpenFolderModel(false)}
        onGetFolderId={(e) => setFolderIds(e)}
        _folders={_folders}
        moveMethod={moveScreenToFolder(_screenId, _folderId)}
        isMoving={isScreenMoving}
      />

      <DeleteModel
        open={openDeleteDialog}
        onClose={(e) => setOpenDeleteDialog(e)}
        onConfirm={confirmDeleteScreen}
      />

      <AlertModel
        open={openDeleteFolder}
        _module={'screen'}
        onClose={() => {
          setFolderIds("");
          setCountScreen(0);
          setOpenDeleteFolder(false);
        }}
        title={"Info"}
        id={_folderId}
        content={
          countScreen == 0
            ? "Do you want to delete?"
            : `${countScreen} screens inside folder do you want to delete it?`
        }
      />

      <Dialog
        maxWidth={"sm"}
        //  fullWidth={true}
        open={alertMsg}
        // open={true}
        onClose={() => setAlertMsg(false)}
      >
        <DialogTitle>
          Your screen has successfully been added to the system.
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              height: "10vh",
              width: "90%",
              padding: "5px",
              dispplay: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>
              We have now sendt Meliora a message to accept the licence
            </span>
            <br />
            <br />
            <span>Your screen will be connceted when Meliora accepts.</span>
            <br />
            <br />
            <span>
              Please read the term`s of the license at:{" "}
              <a href="license.mdisplay.no">license.mdisplay.no</a>
            </span>
          </div>
          <div
            style={{
              height: "5vh",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                height: "25px",
                width: "70px",
                borderRadius: "2px",
                background: "black",
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setAlertMsg(false)}
            >
              Ok
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Add Screen
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <DesktopMacOutlined
                sx={{ fontSize: "100px", color: "var(--primary-color)" }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Code"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Name"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>
                <InputLabel id={"orientation"}>Select Orientation</InputLabel>
                <Select
                  id={"orientation"}
                  onChange={(e) => setOrietation(e.target.value)}
                >
                  <MenuItem value="0">0°</MenuItem>
                  <MenuItem value="90">90°</MenuItem>
                  <MenuItem value="180">180°</MenuItem>
                  <MenuItem value="270">270°</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>
                <InputLabel id={"license"}>Select Licenss</InputLabel>
                <Select
                  required
                  id={"licenss"}
                  onChange={(e) => setLicense(e.target.value)}
                >
                  <MenuItem value="1">1 year</MenuItem>
                  <MenuItem value="2">2 years</MenuItem>
                  <MenuItem value="3">3 years</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={packetTextSearch}
              onChange={searchPacketHandler}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box> */}
            {/* <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            >
              
            </Box>
            {packetFiltered?.map((_, _index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    name={_?.name}
                    value={_?.id}
                    onChange={(e) => {
                      console.log(e.target)
                      onGetScreenAssign(e);
                    }}
                    // checked={_.isChecked}
                    // disabled={_.disable}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>{_?.name}</span>
                </Box>
              </>
            ))}
          </Box> */}

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn-cancel-screen"
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </button>
              <button onClick={submit} className="btn primary btn-save-screen">
                Save
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openEditDialog}
        onClose={() => {
          setIsEditMode(false);
          setOpenEditDialog(false);
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {isEditMode ? <>Select License</> : <>Edit Screen</>}
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <DesktopMacOutlined
                sx={{ fontSize: "100px", color: "var(--primary-color)" }}
              />
            </div>
            {isEditMode ? (
              <>
                <div className="form-fields">
                  <FormControl fullWidth variant={"standard"}>
                    <InputLabel id={"license"}>Select Licenss</InputLabel>
                    <Select
                      required
                      id={"license"}
                      onChange={(e) => setLicense(e.target.value)}
                    >
                      <MenuItem value="1">1 year</MenuItem>
                      <MenuItem value="2">2 years</MenuItem>
                      <MenuItem value="3">3 years</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            ) : (
              <>
                <div className="form-fields">
                  <TextField
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    disabled
                    type={"text"}
                    variant={"standard"}
                    label={"Type Screen Code"}
                    fullWidth
                    sx={{
                      label: {
                        color: "blue",
                        fontSize: "14px",
                        color: "#92959E",
                      },
                    }}
                  />
                </div>
                <div className="form-fields">
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type={"text"}
                    variant={"standard"}
                    label={"Type Screen Name"}
                    fullWidth
                    sx={{
                      label: {
                        color: "blue",
                        fontSize: "14px",
                        color: "#92959E",
                      },
                    }}
                  />
                </div>
                <div className="form-fields">
                  <FormControl fullWidth variant={"standard"}>
                    <InputLabel id={"orientation"}>
                      Select Orientation
                    </InputLabel>
                    <Select
                      id={"orientation"}
                      value={orientation}
                      onChange={(e) => setOrietation(e.target.value)}
                    >
                      <MenuItem value="0">0°</MenuItem>
                      <MenuItem value="90">90°</MenuItem>
                      <MenuItem value="180">180°</MenuItem>
                      <MenuItem value="270">270°</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={packetTextSearch}
              onChange={searchPacketHandler}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box> */}
                {/* <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            >
              
            </Box>
            {packetFiltered?.map((_, _index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    name={_?.name}
                    value={_?._id}
                    onChange={(e) => {
                      console.log(e.target.value)
                      onGetScreenAssign(e);
                    }}
                    // checked={_.isChecked}
                    // disabled={_.disable}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>{_?.name}</span>
                </Box>
              </>
            ))}
          </Box> */}
              </>
            )}

            {/* <div className="add-user-form-submit-btn">
              <button style={{ marginTop: '40px' }} >Save</button>
            </div> */}

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "10px",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setIsEditMode(false);
                  setOpenEditDialog(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "var(--primary-color)",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={isEditMode ? onUpdateLicense : onUpdate}
                className="btn primary"
              >
                Update
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Screen;
