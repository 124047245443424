import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addOverlay,
  deleteContent,
} from "../../../../Redux/Actions/formAction";
import "./image.css";
import { imgUrl } from "../../../../api/api";
import { Layers } from "lucide-react";
function Image(props) {
  const dispatch = useDispatch();
  // const { contentForm, allContent } = useSelector((state) => ({
  //   allContent: state.Form.allContent.map((con)=>({...con,isSelected:false}))
  // }),shallowEqual);
  const [_allContent,setAllContent]=useState([]);
  const [selected,setSelected]=useState([]);
  useEffect(()=>{
    setAllContent(props.content);
  },[])
  const checkBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
       setAllContent(_allContent.map(prev=>prev.id==value ? ({...prev,isSelected:true}) : ({...prev,isSelected:false})))
       props.getSelected(_allContent.filter(d=>d.id==value)[0]);
      // console.log(allContent.find((c) => c.id === value))
      // dispatch(
      //   addOverlay(
      //     props.pId,
      //     allContent.find((c) => c.id === value)
      //   )
      // );
    } else {
      // console.log('down else')
      setAllContent(_allContent.map(prev=>prev.id==value ? {...prev,isSelected:false} : prev))
      // dispatch(deleteContent(props.pId, value));
    }
  };
  // console.log(_allContent)
  return (
    <>
      <div className="image-container">
        {_allContent.map(
          (data) =>
            (data.type === "image" || data.type=='template') && (
              <>
                <div className="image-content-cards" key={data.id}>
                  {/* <div style={{margin:'0 10px'}}> */}

                  <input
                    type="checkbox"
                    name={"radio-for-all"}
                    value={data.id}
                    checked={data.isSelected}
                    onChange={checkBox}
                    id={`checkbox_${data.id}`}
                    style={{ height: "15px", width: "15px", display: "none" }}
                    hidden
                  />
                  <label
                    htmlFor={`checkbox_${data.id}`}
                    className="upper-layer"
                  ></label>
                  {/* </div> */}
                  <div className="image-content-card-image ">
                    {
                      data.name?.split(".")[1] == "png" &&
                    <img src={`${data.url}`} alt="" />
                    }
                    {
                      data.type=='template' &&
                       <div
                       style={{
                         height: {
                           xl: "230px",
                           lg: "230px",
                           md: "230px",
                           sm: "150px",
                           xs: "150px",
                         },
                         width: {
                           xl: "230px",
                           lg: "230px",
                           md: "230px",
                           sm: "150px",
                           xs: "150px",
                         },
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       <Layers
                         style={{
                           height: "60%",
                           width: "60%",
                         }}
                       />
                     </div>
                    }
                  </div>
                  <div className="image-content-card-footer">
                    <p>{data?.name?.substring(0, 20)}</p>
                  </div>
                </div>
              </>
            )
        )}
      </div>
    </>
  );
}

export default Image;
